<template>
    <div ref="TARGET" class="stack" @click="toggleMode ? setVisible(!visible) : setVisible(true)" @mousemove="showHover ? setVisible(true) : null" @mouseleave="showHover ? setVisible(false) : null">
        <slot :action="{ setVisible }" :visible="visible"></slot>
        <teleport :to="`#POPUP_AREA${layer != 0 ? `_LAYER_${layer}` : ''}`">
            <div class="fixed top-0 left-0 duration-300 transition-all" :class="{ 'opacity-0 translate-y-1' : !visible }" :style="`left : ${pos.x}px; top : ${pos.y}px; transition-property: transform, opacity;`">
                <div ref="POPUP">
                    <div class="flex flex-col top-0 left-0 overflow-y-auto transition-all" :class="{
                        'pointer-events-auto' : visible && !showHover,
                        'bg-white shadow-xl rounded-xl border overflow-hidden' : !customCSS,
                        'scale-90': !visible,
                    }">
                        <slot name="popup" :action="{ setVisible }" :visible="visible"></slot>
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script lang="ts">

import { ref, watch, onMounted, onUnmounted } from "vue"

// Optimisation for popup menu
{
    window.addEventListener("resize", NeedUpdate);
    window.addEventListener("wheel", NeedUpdate);
    window.addEventListener("mousemove", NeedUpdate);

    window.addEventListener("mousedown", NeedClickUpdate);
}

const needUpdateCallbacks : any[] = [];
const needClickUpdateCallbacks : any[] = [];

function NeedUpdate(e: any){
    for(let i = 0; i < needUpdateCallbacks.length; i++){
        needUpdateCallbacks[i](e);
    }
}
function NeedClickUpdate(e: any){
    for(let i = 0; i < needClickUpdateCallbacks.length; i++){
        needClickUpdateCallbacks[i](e);
    }
}

function RegisterNeedUpdate(callback: any){
    needUpdateCallbacks.push(callback);
}
function RegisterNeedClickUpdate(callback: any){
    needClickUpdateCallbacks.push(callback);
}

function UnregisterNeedUpdate(callback: any){
    const indexOf = needUpdateCallbacks.indexOf(callback);
    if(indexOf >= 0) needUpdateCallbacks.splice(indexOf, 1);
}
function UnregisterNeedClickUpdate(callback: any){
    const indexOf = needClickUpdateCallbacks.indexOf(callback);
    if(indexOf >= 0) needClickUpdateCallbacks.splice(indexOf, 1);
}

export default {
    emits: ["open", "close"],
    props:{
        hide: {
            type: Boolean,
            default: false,
        },
        toggleMode: {
            type: Boolean,
            default: false,
        },
        showHover: {
            type: Boolean,
            default: false,
        },
        margin: {
            type: Number,
            default: 8,
        },
        layer: {
            type: [Number, String],
            default: 0,
        },
        position: {
            type: String,
            default: "left", //right, left, top, bottom
        },
        justify: {
            type: String,
            default: "center", //center, start, end
        },
        customCSS: {
            type: Boolean,
            default: false,
        }
    },
    setup(props, ctx) {
        const TARGET = ref<HTMLDivElement>();
        const POPUP = ref<HTMLDivElement>();

        const pos = ref({
            x: 0,
            y: 0,
        })

        const dimensions = {
            width: 0,
            height: 0,
        }

        const visible = ref(false);

        watch(() => [props.position, props.justify], ComputePosition);
        watch(visible, () => {
            setTimeout(ComputePosition, 0);
        })

        onMounted(() => {
            RegisterNeedUpdate(ComputePosition);
            RegisterNeedClickUpdate(OnMouseClick);
            ComputePosition();
        })

        onUnmounted(() => {
            UnregisterNeedUpdate(ComputePosition);
            UnregisterNeedClickUpdate(OnMouseClick);
        })

        function OnMouseClick(e: MouseEvent){
            const path : (EventTarget | undefined)[] = e.composedPath();
            if(visible.value && !path?.includes(POPUP.value) && !path?.includes(TARGET.value)) setVisible(false);
        }

        function ComputePosition(){
            if(!POPUP.value || !TARGET.value || !visible.value) return;
            const boudingBoxPopup = POPUP.value?.getBoundingClientRect();
            const boudingBoxTarget = TARGET.value?.getBoundingClientRect();

            if(boudingBoxPopup.width != 0) dimensions.width = boudingBoxPopup.width;
            if(boudingBoxPopup.height != 0) dimensions.height = boudingBoxPopup.height;
            
            //1. position
            pos.value.x = boudingBoxTarget.x; // + boudingBoxTarget.width / 2 - boudingBoxPopup.width / 2;
            pos.value.y = boudingBoxTarget.y; // + boudingBoxTarget.height + props.margin;
            if(props.position == "left" || props.position == "right"){
                pos.value.y += boudingBoxTarget.height / 2 - dimensions.height / 2;
                if(props.justify == "end") pos.value.y = boudingBoxTarget.y - dimensions.height + boudingBoxTarget.height;
                else if(props.justify == "start") pos.value.y = boudingBoxTarget.y;
            }
            if(props.position == "top" || props.position == "bottom"){
                pos.value.x += boudingBoxTarget.width / 2 - dimensions.width / 2;
                if(props.justify == "end") pos.value.x = boudingBoxTarget.x - dimensions.width + boudingBoxTarget.width;
                else if(props.justify == "start") pos.value.x = boudingBoxTarget.x;
            }
            if(props.position == "left") pos.value.x -= dimensions.width + props.margin;
            if(props.position == "right") pos.value.x += boudingBoxTarget.width + props.margin;
            if(props.position == "top") pos.value.y -= dimensions.height + props.margin;
            if(props.position == "bottom") pos.value.y += boudingBoxTarget.height + props.margin;
            
            //Rectification
            {
                const constantMargin = 10;
                if (pos.value.x + dimensions.width + constantMargin > window.innerWidth) {
                    pos.value.x += (window.innerWidth - (pos.value.x + dimensions.width + constantMargin))
                }
                if (pos.value.x - constantMargin < 0) {
                    pos.value.x = constantMargin
                }
            }

            pos.value.x = Math.round(pos.value.x);
            pos.value.y = Math.round(pos.value.y);
        }

        function setVisible(value = true){
            if(props.hide) value = false;
            const oldVal = visible.value;
            visible.value = value;

            if(oldVal != visible.value){
                if(visible.value) ctx.emit("open");
                else ctx.emit("close");
            }
            
            // setTimeout(ComputePosition);
        }

        return {
            TARGET,
            POPUP,

            pos,
            visible,

            setVisible
        }
    },
}
</script>